import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createReducer from './reducers';

const logger = createLogger({
  duration: true,
});

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;

  let middlewares = [routerMiddleware(history), thunk];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  return store;
}
