export const sessionErrorMessages = {
  'auth/please-check-your-email': '詳細な手順については、メールを確認してください',
  // displayName is null.
  'auth/name-is-null': 'あなたの名前を教えてください',
  // This browser is not supported.
  'auth/cors-unsupported':
    'このブラウザは現在サポートしていません。最新バージョンにアップデートしてください。https://browsehappy.com/',

  // This credential is already associated with a different user account.
  'auth/credential-already-in-use': 'このユーザ名はすでに使われています。',

  // This operation is sensitive and requires recent authentication. Log in again before retrying this request.
  'auth/requires-recent-login':
    'この操作はセキュリティ保護のため再認証が必要です。お手数ですが再度ログインし直してから操作を行なってください。',

  // The email address is already in use by another account.
  'auth/email-already-in-use': 'このメールアドレスはすでに登録されています。',

  // This operation has been cancelled due to another conflicting popup being opened.
  'auth/cancelled-popup-request': 'このメールアドレスは別のアカウントですでに登録されています。',

  // An internal error has occurred.
  'auth/internal-error': '内部エラーが発生しました。',

  // The email address is badly formatted.
  'auth/invalid-email': '正しいメールアドレスを入力してください。',

  // The password is invalid or the user does not have a password.
  'auth/wrong-password': 'このパスワードは誤りがあるか、このアカウントにはパスワードが設定されていません。',

  // Unable to establish a connection with the popup. It may have been blocked by the browser.
  'auth/popup-blocked': 'ポップアップがブロックされました。ご利用のブラウザにブロックされている可能性があります。',

  // The popup has been closed by the user before finalizing the operation.
  'auth/popup-closed-by-user': 'このポップアップは操作が完了する前に閉じられました。',

  // The operation has timed out.
  'auth/timeout': 'この操作はタイムアウトしました。',

  // The user's credential is no longer valid. The user must sign in again.
  'auth/user-token-expired': 'このアカウントは無効です。新規登録を行なってください。',

  // We have blocked all requests from this device due to unusual activity. Try again later.
  'auth/too-many-requests': '不正なログインが検出されたためアカウントをブロックしました。',

  //An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.
  'auth/account-exists-with-different-credential':
    'このメールアドレスは他のログイン方法で登録されています。登録時のログイン方法でログインしてください。',

  // There is no user record corresponding to this identifier. The user may have been deleted.
  'auth/user-not-found':
    'この識別情報に該当するユーザデータが見つかりませんでした。このユーザは削除された可能性があります。',

  // The user account has been disabled by an administrator.
  'auth/user-disabled': 'このアカウントは運営者によって無効になりました。',

  // The password must be 6 characters long or more.
  'auth/weak-password': 'パスワードは6文字以上の英数字に設定してください。',

  // This browser is not supported or 3rd party cookies and data may be disabled.
  'auth/web-storage-unsupported': 'このブラウザは使用不可もしくは Cookieが無効になっています。',

  // auth/api-error
  'auth/api-error': '不明なエラーが発生しました。後の行で再試行してください',
};
