import * as types from './constants';
const initialState = {
  user: null,
  error: null,
  loading: false,
  restoring: false,
  loggedIn: null,
  loggedOut: null,
  updated: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SESSION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SESSION_RESTORING:
      return {
        ...state,
        restoring: true,
      };
    case types.SESSION_LOGOUT:
      return {
        user: null,
        error: null,
        loading: false,
        restoring: false,
        loggedIn: false,
        loggedOut: true,
      };
    case types.SESSION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.SESSION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
        updated: null,
      };
    case types.SESSION_SUCCESS:
      return {
        user: action.user,
        error: null,
        loading: false,
        restoring: false,
        loggedIn: true,
        loggedOut: null,
      };
    case types.SESSION_UPDATED:
      return {
        ...state,
        loading: false,
        error: null,
        updated: true,
        user: {
          ...state.user,
          info: {
            ...state.user.info,
            ...action.profile,
          },
        },
      };
    default:
      return state;
  }
};

export default sessionReducer;
