const Salaries = [
  { label: '200万未満', value: '200万未満' },
  { label: '200万~400万', value: '200万~400万' },
  { label: '400万~600万', value: '400万~600万' },
  { label: '600万~800万', value: '600万~800万' },
  { label: '800万~1,000万', value: '800万~1,000万' },
  { label: '1,000万~1,500万', value: '1,000万~1,500万' },
  { label: '1,500万~2,000万', value: '1,500万~2,000万' },
  { label: '2,000万~3,000万', value: '2,000万~3,000万' },
  { label: '3,000万以上', value: '3,000万以上' },
];
export default Salaries;
