import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ja';
import MomentUtils from '@date-io/moment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonLinear from '../../components/ButtonLinear';
import ButtonGrey from '../../components/ButtonGrey';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { FormLabel, RequiredLabel, FormRow, FormColumn, FormLabelSm } from '../../components/Typography';

import AvatarPicker from '../../components/AvatarPicker';
import Prefectures from '../../utils/prefectures';
import Wards from '../../utils/23-wards';
import LifeStages from '../../utils/life-stages';
import Salaries from '../../utils/salaries';
import JobTitles from '../../utils/job-titles';

import { update } from '../../sessions/actions';
import AppLayout from '../../components/AppLayout';
import SkillOut from './_skill-out';
import SkillIn from './_skill-in';
import SharingFee from './_sharing-fee';
import Hobbies from './_hobbies';
import KidsInfo from './_kids-info';

moment.locale('ja');

class EditProfileContainer extends React.Component {
  state = {};

  componentDidMount() {
    let tmpProfile = this.props.session.user.info;

    if (tmpProfile.displayName && !tmpProfile.name) {
      tmpProfile.name = tmpProfile.displayName;
    }

    if (tmpProfile.photoURL && !tmpProfile.imageUrl) {
      tmpProfile.imageUrl = tmpProfile.photoURL;
    }

    if (!tmpProfile.sharingFees) tmpProfile.sharingFees = ['thank', 'coffee', 'lunch'];

    this.setState({ userProfile: tmpProfile, mounted: true });
  }

  handleAvatarChange = (cloudImg) => {
    this.setProfile('imageUrl', cloudImg.url);
  };

  setProfile = (k, v) => {
    let { userProfile } = this.state;
    userProfile[k] = v;
    if (k === 'prefecture' && v !== '東京都') userProfile.ward = null;
    if (k === 'hasKid' && v === false) userProfile.kidsInfo = null;
    this.setState({ userProfile: userProfile });
  };

  validate = () => {
    if (!this.state.userProfile.name) return false;
    if (!this.state.userProfile.imageUrl) return false;
    if (!this.state.userProfile.prefecture) return false;
    // if (!this.state.userProfile.onBoarding.agreement) return false;

    if (this.state.userProfile.hasKid === true) {
      if (!this.state.userProfile.kidsInfo) return false;
    }

    if (!Array.isArray(this.state.userProfile.skillsIn)) return false;
    if (!this.state.userProfile.skillsIn.length) return false;

    if (!Array.isArray(this.state.userProfile.skillsOut)) return false;
    if (!this.state.userProfile.skillsOut.length) return false;

    if (!this.state.userProfile.sharingFees) return false;
    if (Array.isArray(this.state.userProfile.sharingFees)) {
      if (!this.state.userProfile.sharingFees.length) return false;
      let money_fees = this.state.userProfile.sharingFees.find((sf) => typeof sf === 'object');
      if (money_fees) {
        if (!money_fees.money['30m'] && !money_fees.money['60m'] && !money_fees.money['90m']) {
          return false;
        }
      }
    }

    return true;
  };

  saveProfile = () => {
    if (!this.validate()) return false;

    const {
      user: { jwt, info },
    } = this.props.session;
    let { userProfile } = this.state;
    this.props.updateProfile(jwt, userProfile).then(() => {
      this.props.history.push(`/u/${info.id}`);
    });
  };

  render() {
    const { userProfile } = this.state;
    if (!userProfile) return <div />;

    return (
      <AppLayout bgWhite>
        {/*  */}
        <FormRow style={{ marginTop: '25px', display: 'flex', alignItems: 'center' }}>
          <FormLabel style={{ marginBottom: '10px' }}>
            プロフィール画像<RequiredLabel>*</RequiredLabel>
          </FormLabel>
          <AvatarPicker imageUrl={userProfile.imageUrl} handleAvatarChange={this.handleAvatarChange} />
        </FormRow>
        {/*  */}
        <SkillOut userProfile={this.state.userProfile} setProfile={this.setProfile} jwt={this.props.session.user.jwt} />
        {/*  */}
        <SkillIn userProfile={this.state.userProfile} setProfile={this.setProfile} jwt={this.props.session.user.jwt} />
        {/*  */}
        <SharingFee userProfile={this.state.userProfile} setProfile={this.setProfile} />
        {/*  */}
        <FormRow style={{ marginTop: '35px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              checked={userProfile.pro_apply}
              onChange={(e) => this.setProfile('pro_apply', e.target.checked)}
              name="checked-pro-apply"
              disabled={userProfile.pro_verified}
              color="primary"
              style={{ paddingLeft: '0px' }}
            />
            <span>プロメンターとして申請する</span>
          </div>
          <FormLabelSm>
            ※ビジネスコーチ、キャリアカウンセラーの資格をお持ちの方はプロフィールに「プロ」マークをお付けします。ご希望の方はチェックを入れてください。後日ご案内メールをお送りしますので、資格証明を添付してご返信ください。確認後「プロ」マークを付与致します。
          </FormLabelSm>
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>こんな話ができます</FormLabel>
          <TextField
            multiline
            rows={4}
            value={userProfile.speakOut || ''}
            onChange={(e) => this.setProfile('speakOut', e.target.value)}
          />
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>自己紹介</FormLabel>
          <TextField
            multiline
            rows={4}
            value={userProfile.introduce || ''}
            onChange={(e) => this.setProfile('introduce', e.target.value)}
          />
        </FormRow>
        {/*  */}
        <Hobbies userProfile={this.state.userProfile} setProfile={this.setProfile} jwt={this.props.session.user.jwt} />
        {/*  */}
        <FormRow>
          <FormLabel>基本情報</FormLabel>
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>
            ニックネーム<RequiredLabel>*</RequiredLabel>
          </FormLabel>
          <TextField value={userProfile.name || ''} onChange={(e) => this.setProfile('name', e.target.value)} />
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>生年月日</FormLabel>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locacle={'ja'}>
            <DatePicker
              disableFuture
              openTo="year"
              format="YYYY-MM-DD"
              views={['year', 'month', 'date']}
              value={userProfile.dob || null}
              onChange={(date) => this.setProfile('dob', date.format('YYYY-MM-DD'))}
            />
          </MuiPickersUtilsProvider>
        </FormRow>
        {/*  */}
        <FormColumn>
          <FormRow style={{ width: '50%', paddingRight: '5px' }}>
            <FormLabel>
              お住まいの地域<RequiredLabel>*</RequiredLabel>
            </FormLabel>
            <TextField
              select
              value={userProfile.prefecture || ''}
              onChange={(e) => this.setProfile('prefecture', e.target.value)}>
              {Prefectures.map((pref) => (
                <MenuItem key={pref.value} value={pref.value}>
                  {pref.label}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
          {userProfile.prefecture === '東京都' && (
            <FormRow style={{ width: '50%', paddingLeft: '5px' }}>
              <FormLabel>23区</FormLabel>
              <TextField
                select
                value={userProfile.ward || ''}
                onChange={(e) => this.setProfile('ward', e.target.value)}>
                {Wards.map((ward) => (
                  <MenuItem key={ward.value} value={ward.value}>
                    {ward.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormRow>
          )}
        </FormColumn>
        {/*  */}
        <FormRow>
          <FormLabel>職業</FormLabel>
          <TextField value={userProfile.job || ''} onChange={(e) => this.setProfile('job', e.target.value)} />
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>現在のライフステージ</FormLabel>
          <TextField
            select
            value={userProfile.lifeStage || ''}
            onChange={(e) => this.setProfile('lifeStage', e.target.value)}>
            {LifeStages.map((ls) => (
              <MenuItem key={ls.value} value={ls.value}>
                {ls.label}
              </MenuItem>
            ))}
          </TextField>
        </FormRow>
        {/*  */}
        <KidsInfo userProfile={this.state.userProfile} setProfile={this.setProfile} />
        {/*  */}
        <FormRow>
          <FormLabel>公開していない情報</FormLabel>
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>年収</FormLabel>
          <TextField
            select
            value={userProfile.salary || ''}
            onChange={(e) => this.setProfile('salary', e.target.value)}>
            {Salaries.map((slr) => (
              <MenuItem key={slr.value} value={slr.value}>
                {slr.label}
              </MenuItem>
            ))}
          </TextField>
        </FormRow>
        {/*  */}
        <FormRow>
          <FormLabel>役職</FormLabel>
          <TextField
            select
            value={userProfile.jobTitle || ''}
            onChange={(e) => this.setProfile('jobTitle', e.target.value)}>
            {JobTitles.map((jt) => (
              <MenuItem key={jt.value} value={jt.value}>
                {jt.label}
              </MenuItem>
            ))}
          </TextField>
        </FormRow>
        {/*  */}
        <FormColumn style={{ margin: '50px 0', justifyContent: 'center' }}>
          <ButtonGrey
            width={'120px'}
            title={'キャンセル'}
            disabled={false}
            cb={() => this.props.history.push(`/u/${this.props.session.user.info.id}`)}
          />
          <ButtonLinear width={'120px'} title={'保存'} disabled={!this.validate()} cb={() => this.saveProfile()} />
        </FormColumn>
      </AppLayout>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session: session,
});

const mapDispatchToProps = {
  updateProfile: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileContainer);
