import React from 'react';
import styled from 'styled-components';
import { FormLabel, FormRow, FormColumn, FormLabelNmr } from '../../components/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import checkImg from '../../assets/icons/checkCirleBtnOn.png';
import thankImg from '../../assets/icons/thanks.png';
import coffeeImg from '../../assets/icons/coffee.png';
import lunchImg from '../../assets/icons/lunch.png';
import moneyImg from '../../assets/icons/money-jpy.png';

export default class SharingFee extends React.Component {
  toggleFee = (type) => {
    let { sharingFees } = this.props.userProfile;
    if (sharingFees.includes(type)) {
      sharingFees = sharingFees.filter((f) => f !== type);
    } else {
      sharingFees.push(type);
    }
    this.props.setProfile('sharingFees', sharingFees);
  };

  hasMoneyFee = (sfs) => sfs.find((sf) => typeof sf === 'object');

  toggleMoneyFee = () => {
    let { sharingFees } = this.props.userProfile;
    if (this.hasMoneyFee(sharingFees)) {
      sharingFees = sharingFees.filter((sf) => typeof sf !== 'object');
    } else {
      sharingFees.push({
        money: { '30m': '', '60m': '', '90m': '' },
      });
    }
    this.props.setProfile('sharingFees', sharingFees);
  };

  setMoneyFee = (fee, duration) => {
    if (parseInt(fee) <= 0) return;

    let { sharingFees } = this.props.userProfile;
    let non_money_fee = sharingFees.filter((sf) => typeof sf !== 'object');
    if (!non_money_fee) non_money_fee = [];

    let money_fee = sharingFees.find((sf) => typeof sf === 'object');
    if (!money_fee) return;

    money_fee.money[duration] = fee;
    non_money_fee.push(money_fee);
    this.props.setProfile('sharingFees', non_money_fee);
  };

  render() {
    const { userProfile } = this.props;
    const userMoneyFee = this.hasMoneyFee(userProfile.sharingFees);
    return (
      <section style={{ width: '100%' }}>
        <FormRow>
          <FormLabel>希望の謝礼・報酬</FormLabel>
        </FormRow>

        <FormRow>
          <FormColumn>
            <SharingFeeWraper
              checked={userProfile.sharingFees.includes('thank')}
              onClick={() => this.toggleFee('thank')}>
              <SharingFeeVisual>
                <img style={{ height: '30px', width: '36px' }} src={thankImg} alt="" />
              </SharingFeeVisual>
              <SharingFeeText>
                <strong>ありがとう</strong>
              </SharingFeeText>
              {userProfile.sharingFees.includes('thank') && (
                <SharingFeeCheck>
                  <img src={checkImg} alt="" style={{ width: '24px', height: '24px' }} />
                </SharingFeeCheck>
              )}
            </SharingFeeWraper>

            <SharingFeeWraper
              checked={userProfile.sharingFees.includes('coffee')}
              onClick={() => this.toggleFee('coffee')}>
              <SharingFeeVisual>
                <img style={{ height: '47px', width: '32px' }} src={coffeeImg} alt="" />
              </SharingFeeVisual>
              <SharingFeeText>
                <strong>カフェ代</strong>
              </SharingFeeText>
              {userProfile.sharingFees.includes('coffee') && (
                <SharingFeeCheck>
                  <img src={checkImg} alt="" style={{ width: '24px', height: '24px' }} />
                </SharingFeeCheck>
              )}
            </SharingFeeWraper>

            <SharingFeeWraper
              checked={userProfile.sharingFees.includes('lunch')}
              onClick={() => this.toggleFee('lunch')}>
              <SharingFeeVisual>
                <img style={{ height: '46px', width: '20px' }} src={lunchImg} alt="" />
              </SharingFeeVisual>
              <SharingFeeText>
                <strong>ランチ代</strong>
              </SharingFeeText>
              {userProfile.sharingFees.includes('lunch') && (
                <SharingFeeCheck>
                  <img src={checkImg} alt="" style={{ width: '24px', height: '24px' }} />
                </SharingFeeCheck>
              )}
            </SharingFeeWraper>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <SharingFeeWraper checked={userMoneyFee} onClick={() => this.toggleMoneyFee()}>
              <SharingFeeVisual>
                <img style={{ height: '32px', width: '32px' }} src={moneyImg} alt="" />
              </SharingFeeVisual>
              <SharingFeeText>
                <strong>料金</strong>
              </SharingFeeText>
              {userMoneyFee && (
                <SharingFeeCheck>
                  <img src={checkImg} alt="" style={{ width: '24px', height: '24px' }} />
                </SharingFeeCheck>
              )}
            </SharingFeeWraper>
            <MoneyFeesWraper>
              <MoneyFeeRow>
                <FormLabelNmr>30分</FormLabelNmr>
                <Input
                  style={{ width: '140px', marginLeft: '15px', paddingLeft: '10px', fontSize: '13px' }}
                  placeholder="1000"
                  type={'number'}
                  value={userMoneyFee ? userMoneyFee.money['30m'] : ''}
                  disabled={!userMoneyFee}
                  onChange={(e) => this.setMoneyFee(e.target.value, '30m')}
                  endAdornment={<InputAdornment position="end">円（税別）</InputAdornment>}
                />
              </MoneyFeeRow>
              <MoneyFeeRow>
                <FormLabelNmr>60分</FormLabelNmr>
                <Input
                  style={{ width: '140px', marginLeft: '15px', paddingLeft: '10px', fontSize: '13px' }}
                  placeholder="2000"
                  type={'number'}
                  value={userMoneyFee ? userMoneyFee.money['60m'] : ''}
                  disabled={!userMoneyFee}
                  onChange={(e) => this.setMoneyFee(e.target.value, '60m')}
                  endAdornment={<InputAdornment position="end">円（税別）</InputAdornment>}
                />
              </MoneyFeeRow>
              <MoneyFeeRow>
                <FormLabelNmr>90分</FormLabelNmr>
                <Input
                  style={{ width: '140px', marginLeft: '15px', paddingLeft: '10px', fontSize: '13px' }}
                  placeholder="3000"
                  type={'number'}
                  value={userMoneyFee ? userMoneyFee.money['90m'] : ''}
                  disabled={!userMoneyFee}
                  onChange={(e) => this.setMoneyFee(e.target.value, '90m')}
                  endAdornment={<InputAdornment position="end">円（税別）</InputAdornment>}
                />
              </MoneyFeeRow>
            </MoneyFeesWraper>
          </FormColumn>
        </FormRow>
      </section>
    );
  }
}

const SharingFeeWraper = styled.div`
  width: 95px;
  height: 95px;
  margin-left: 10px;
  cursor: pointer;
  border: ${(props) => (props.checked ? '2px solid #5870FF' : '2px solid #DDDDDD')};
  color: ${(props) => (props.checked ? '#5870FF' : '#DDDDDD')};
  border-radius: 8px;
  position: relative;
`;

const SharingFeeVisual = styled.div`
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
`;

const SharingFeeText = styled.div`
  text-align: center;
`;

const SharingFeeCheck = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -6px;
  margin-right: -8px;
`;

const MoneyFeesWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MoneyFeeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;
