import React from 'react';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormLabel, FormRow, FormColumn } from '../../components/Typography';

import { apiPostGraphql } from '../../utils/api';

const filter = createFilterOptions();

export default class Hobbies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openSuggestion: false,
      skillsPopular: [],
      skillsSuggest: [],
      searchInput: '',
      hobbiesputFocus: false,
    };
    this.getSkills = _.debounce(this.getSkills, 500);
  }

  handlePostGraphql = async (query, variables) => {
    return await apiPostGraphql(this.props.jwt, query, variables);
  };

  async getSkills(txt) {
    this.setState({ loading: true });
    let { suggestions } = await this.handlePostGraphql(getSkillsGQL, {
      where: { value_contains: txt, type: 'profile_hobbies' },
    });
    if (!suggestions) suggestions = [];
    this.setState({ skillsSuggest: suggestions, loading: false });
  }

  selectSkill = (skl) => {
    if (!skl) return;
    skl = skl.trim();
    let { hobbies } = this.props.userProfile;
    if (!hobbies) hobbies = [];
    if (!hobbies.includes(skl)) {
      hobbies.push(skl);
      this.props.setProfile('hobbies', hobbies);
    }
    this.setState({
      searchInput: '',
    });
  };

  removeSkill = (skl) => {
    let { hobbies } = this.props.userProfile;
    if (!hobbies) hobbies = [];
    if (hobbies.includes(skl)) {
      hobbies = hobbies.filter((s) => s !== skl);
      this.props.setProfile('hobbies', hobbies);
    }
  };

  skillsChange = async (txt) => {
    this.setState({ searchInput: txt }, () => {
      if (!txt) {
        this.setState({ skillsSuggest: [] });
      } else {
        this.getSkills(txt);
      }
    });
  };

  render() {
    const { userProfile } = this.props;
    return (
      <section style={{ width: '100%' }}>
        <FormRow>
          <FormLabel>趣味・価値観</FormLabel>
        </FormRow>

        <FormRow>
          <FormColumn style={{ flexWrap: 'wrap' }}>
            {(userProfile.hobbies || []).map((skill, index) => (
              <Chip
                key={index}
                variant="outlined"
                size="small"
                color="primary"
                label={`#${skill}`}
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onDelete={() => this.removeSkill(skill)}
              />
            ))}
          </FormColumn>

          <Autocomplete
            open={this.state.openSuggestion}
            inputValue={this.state.searchInput}
            onOpen={() => this.setState({ openSuggestion: true })}
            onClose={() => this.setState({ openSuggestion: false })}
            onChange={(e, v) => this.selectSkill(v ? v.value : null)}
            getOptionLabel={(option) => option.value}
            options={this.state.skillsSuggest}
            loading={this.state.loading}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  value: params.inputValue,
                });
              }

              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => this.skillsChange(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormRow>
      </section>
    );
  }
}

const getSkillsGQL = `query getSuggestion($where: JSON!) {
  suggestions(where: $where, limit: 20) {
    value
  }
}`;
