import React from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Switch, Route } from 'react-router-dom';

import 'sanitize.css';
import GlobalStyle from './styles';

import { restoreSession } from './app/sessions/actions';

import SplashScreen from './app/components/SplashScreen';
import AuthContainer from './app/containers/Auth';
import OnboardingContainer from './app/containers/Onboarding';
import TopContainer from './app/containers/Top';
import UserPageContainer from './app/containers/UserPage';
import MessageContainer from './app/containers/Message';
import MessageDetailContainer from './app/containers/MessageDetail';
import MentorReviewContainer from './app/containers/RequestReviews/mentor-review';
import MenteeReviewContainer from './app/containers/RequestReviews/mentee-review';
import MenteeThankyouContainer from './app/containers/RequestReviews/mentee-thankyou';

import EditProfileContainer from './app/containers/MyPage/edit-profile';
import EditTimelineContainer from './app/containers/MyPage/edit-timeline';

import ContactContainer from './app/containers/Contact';
import ChangeEmailContainer from './app/containers/ChangeEmail';
import ChangePasswordContainer from './app/containers/ChangePassword';

import { clearError } from './app/sessions/actions';
import { sessionErrorMessages } from './app/sessions/errors';

class App extends React.Component {
  componentDidMount() {
    this.props.restoreSession();
  }
  _finishProfile = (profiles) => {
    if (!Array.isArray(profiles)) return false;

    return (
      profiles.includes('step1') &&
      profiles.includes('step2') &&
      profiles.includes('step3') &&
      profiles.includes('step4')
    );
  };
  render() {
    const { session } = this.props;
    return (
      <React.Fragment>
        {session.restoring ? (
          <AppSplashing />
        ) : (
          <React.Fragment>
            {session.loggedOut === true && <AppLoggedOut />}
            {session.loggedIn === true && (
              <React.Fragment>
                {this._finishProfile(session.user.info.onBoarding.profiles) &&
                session.user.info.status === 'activated' ? (
                  <AppLoggedIn />
                ) : (
                  <AppOnboading />
                )}
              </React.Fragment>
            )}

            <Backdrop open={session.loading} style={{ zIndex: 999 }}>
              <CircularProgress style={{ color: '#fff' }} />
            </Backdrop>

            {session.error !== null && (
              <Snackbar open={true}>
                <Alert onClose={this.props.clearError} severity="error">
                  {sessionErrorMessages[session.error] || '不明なエラー'}
                </Alert>
              </Snackbar>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const AppLoggedIn = () => (
  <React.Fragment>
    <Switch>
      <Route exact path="/" render={TopContainer} />
      <Route path="/connect/:page?" render={TopContainer} />
      <Route path="/u/:uid?" render={UserPageContainer} />
      <Route path="/message/:tab?/:page?" render={MessageContainer} />

      <Route path="/rf/:rid?/:uname?/arigatou" component={MenteeThankyouContainer} />
      <Route path="/r/:rid?/:uname?/mentor-review" component={MentorReviewContainer} />
      <Route path="/r/:rid?/:uname?/mentee-review" component={MenteeReviewContainer} />
      <Route path="/r/:rid?/:uname?" render={MessageDetailContainer} />

      <Route path="/me/edit-profile" component={EditProfileContainer} />
      <Route path="/me/edit-timeline" component={EditTimelineContainer} />
      <Route path="/me/change-email" component={ChangeEmailContainer} />
      <Route path="/me/change-password" component={ChangePasswordContainer} />

      <Route path="/contact" component={ContactContainer} />
    </Switch>
    <GlobalStyle />
  </React.Fragment>
);

const AppOnboading = () => (
  <section style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
    <OnboardingContainer />
    <GlobalStyle />
  </section>
);

const AppLoggedOut = () => (
  <Container style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
    <AuthContainer />
    <GlobalStyle />
  </Container>
);

const AppSplashing = () => (
  <Container>
    <SplashScreen />
    <GlobalStyle />
  </Container>
);
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const mapStateToProps = ({ session }) => ({
  session: session,
});

const mapDispatchToProps = {
  restoreSession: restoreSession,
  clearError: clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
