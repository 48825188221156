import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Rating from '@material-ui/lab/Rating';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import AppLayout from '../../components/AppLayout';
import { apiPostGraphql, apiPutRequest } from '../../utils/api';

import cover from '../../assets/images/mentoring_close_cover.png';
import connectDot from '../../assets/icons/connect-dot.png';
import ButtonOrange from '../../components/ButtonOrange';

import { FormLabelSm, FormLabel, FormRow, RequiredLabel } from '../../components/Typography';

// const StyledRating = withStyles({
//   iconFilled: {
//     color: '#ff6d75',
//   },
//   iconHover: {
//     color: '#ff3d47',
//   },
// })(Rating);

const initialState = {
  isSubmiting: false,
  isGetting: false,
  reviewRating: 0,
  reviewContent: '',
  reviewExpected: '',
};

class MentorReviewContainer extends React.Component {
  state = initialState;

  validateRid = (rid) => {
    // https://github.com/mongodb/js-bson/blob/c307ca8e81b73ccfcac0b54e5012123773d30ddb/lib/objectid.js#L12
    const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    return checkForHexRegExp.test(rid);
  };

  initRequest = () => {
    const { rid, uname } = this.getFromUrl();
    const rid_valid = this.validateRid(rid);
    if (!rid_valid) {
      this.setState({ isGetting: true });
      return;
    }
    this.setState({ rid: rid, uname: uname }, async () => {
      await this.getRequestDetail();
    });
  };

  async componentDidMount() {
    this.initRequest();
  }

  getFromUrl = () => {
    let { params } = this.props.match;
    if (!params) return 0;
    let { rid, uname } = params;
    if (!rid) rid = 0;
    if (!uname) uname = '';

    return { rid, uname };
  };

  getRequestDetail = async () => {
    let { request } = await apiPostGraphql(this.props.session.user.jwt, getRequestDetailGQL, { id: this.state.rid });
    if (!request) return;
    // validate mentee
    if (request.receiverID !== this.props.session.user.info.id) return;

    this.setState({ request: request });
  };

  submitReview = async () => {
    this.setState({ isSubmiting: true }, async () => {
      const res = await apiPutRequest(this.props.session.user.jwt, 'connectService/close', {
        requestID: this.state.rid,
        reviewRating: this.state.reviewRating,
        reviewContent: this.state.reviewContent,
        reviewExpected: this.state.reviewExpected,
        reviewerInfo: {
          role: 'mentor',
          name: this.props.session.user.info.name,
          imageUrl: this.props.session.user.info.imageUrl,
        },
        participants: this.state.request.participants,
      });
      if (res) {
        this.props.history.push(`/r/${this.state.rid}/${this.state.uname}`);
      }
    });
  };

  render() {
    return (
      <AppLayout>
        {/* loading */}
        {this.state.isGetting || !this.state.request ? (
          <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            {/*  */}
            <Breadcrumbs separator=">" style={{ margin: '24px 0' }}>
              <Link to="/">
                <Typography>Top</Typography>
              </Link>
              <Link to="/message">
                <Typography>メッセージ</Typography>
              </Link>
              <Link to={`/r/${this.state.request.id}/${this.state.uname}`}>
                <Typography>{this.state.uname}</Typography>
              </Link>
              <Typography>メンタリング終了</Typography>
            </Breadcrumbs>
            {/*  */}
            <CoverWraper>
              <WhiteParagraph>メンタリングはいかがでしたか？</WhiteParagraph>
              <WhiteParagraph>お相手に「紹介メッセージ」を送りましょう！</WhiteParagraph>
              <AvatarsWrapper>
                <AvatarImg src={this.state.request.metaData.receiverInfo.imageUrl} />
                <AvatarImg src={this.state.request.metaData.senderInfo.imageUrl} />
              </AvatarsWrapper>
            </CoverWraper>

            <FormRow style={{ marginTop: '20px' }}>
              <FormLabel>お相手の紹介メッセージ</FormLabel>
              <FormLabelSm>
                (後日お相手のプロフィールに表示されます) <br />
                例：「〇〇さんはとても熱心に今後のキャリアについて考えていらっしゃり、お話していて私も刺激を受けました。私の体験談も参考になったようで、少しでもお役に立てて嬉しかったです。」\n\nお話してよかったこと、感想・感じたこと、お相手の印象などポジティブな紹介メッセージを書きましょう！
              </FormLabelSm>
              <BgWhiteWraper>
                <TextField
                  multiline
                  rows={4}
                  placeholder={`メッセージを入力してください`}
                  value={this.state.reviewContent || ''}
                  onChange={(e) => this.setState({ reviewContent: e.target.value })}
                />
              </BgWhiteWraper>
            </FormRow>

            <FormRow style={{ marginTop: '20px' }}>
              <FormLabel>
                このメンタリングに期待していたことを得ることはできましたか？<RequiredLabel>*</RequiredLabel>
              </FormLabel>
              <FormLabelSm>
                (この回答はお相手には送られず、Clarity Mentor運営局にて今後のサービス向上のために集計させて頂きます。)
              </FormLabelSm>
              <BgWhiteWraper>
                <TextField
                  select
                  value={this.state.reviewExpected || ''}
                  placeholder="選択してください"
                  onChange={(e) => this.setState({ reviewExpected: e.target.value })}>
                  <MenuItem value="期待以上に得られた">期待以上に得られた</MenuItem>
                  <MenuItem value="得られなかった">得られなかった</MenuItem>
                  <MenuItem value="得ることができた">得ることができた</MenuItem>
                </TextField>
              </BgWhiteWraper>
            </FormRow>

            <FormRow style={{ marginTop: '15px', display: 'flex', alignItems: 'center' }}>
              <ButtonOrange
                title="送信する"
                cb={() => this.submitReview()}
                disabled={!this.state.reviewExpected || this.state.isSubmiting}
              />
            </FormRow>
          </React.Fragment>
        )}
      </AppLayout>
    );
  }
}

const CoverWraper = styled.div`
  background-image: url(${cover});
  background-position: center center;
  background-size: cover;
  align-items: center;
  // justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin: 0 -24px;
  height: 150px;
  position: relative;
  @media (max-width: 375px) {
    margin: 0 -16px;
  }
`;

const BgWhiteWraper = styled.div`
  margin: 12px -24px 0 -24px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  & > .MuiFormControl-root {
    width: 100%;
    & > .MuiInput-underline:before {
      border: none !important;
    }
  }
  @media (max-width: 375px) {
    margin: 12px -16px 0 -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AvatarsWrapper = styled.div`
  width: 226px;
  height: 89px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: -30px;
  background-image: url(${connectDot});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const WhiteParagraph = styled.p`
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  margin: 0;
`;

const AvatarImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  object-position: center center;
  border: 2px solid rgba(255, 255, 255, 0.3);
`;

const getRequestDetailGQL = `
query getRequestDetail ($id: ID!) {
  request(id: $id) {
    id
    senderID
    receiverID
    metaData
    lastMessage
    status
    participants
    mentorReviewed
    menteeReviewed
  }
}`;

const mapStateToProps = ({ session }) => ({
  session: session,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MentorReviewContainer);
