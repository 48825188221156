import axios from 'axios';

const backendUrl = process.env.REACT_APP_API_URL;

export const apiPostGraphql = async (jwt, gqlQuery, variables) => {
  if (!jwt) jwt = getJWT();
  try {
    let apiRes = await axios.post(
      `${backendUrl}/graphql`,
      { query: gqlQuery, variables: variables },
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
    return apiRes.data.data;
  } catch {
    return null;
  }
};

export const apiPutRequest = async (jwt, endpoint, variables) => {
  if (!jwt) jwt = getJWT();
  try {
    let apiRes = await axios.put(`${backendUrl}/${endpoint}`, variables, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return apiRes.data;
  } catch {
    return null;
  }
};

export const apiPostRequest = async (jwt, endpoint, variables) => {
  if (!jwt) jwt = getJWT();
  try {
    let apiRes = await axios.post(`${backendUrl}/${endpoint}`, variables, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    return apiRes.data;
  } catch {
    return null;
  }
};

const getJWT = () => {
  let userSessionJSON = localStorage.getItem('session');
  if (!userSessionJSON) return false;

  let userSession = JSON.parse(userSessionJSON);
  return userSession.jwt;
};
