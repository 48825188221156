import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorGrey = withStyles(() => ({
  root: {
    marginLeft: '6px',
    marginRight: '6px',
    color: '#333333 !important',
    height: '45px',
    borderRadius: '22px',
    fontWeight: '600',
    background: '#F2F2F2',
    '&:hover': {
      opacity: 0.9,
    },
    '&:disabled': {
      opacity: 0.5,
      color: '#333333 !important',
    },
  },
}))(Button);

const ButtonGrey = ({ width = '150px', title, cb, disabled = false }) => (
  <ColorGrey style={{ width: width }} disabled={disabled} onClick={() => cb()}>
    {title}
  </ColorGrey>
);

export default ButtonGrey;
