const LifeStages = [
  { label: '独身', value: '独身' },
  { label: '婚約中', value: '婚約中' },
  { label: '既婚', value: '既婚' },
  { label: '事実婚・パートナー', value: '事実婚・パートナー' },
  { label: '妊活中', value: '妊活中' },
  { label: '妊娠中', value: '妊娠中' },
  { label: '子育て中', value: '子育て中' },
  { label: '介護中', value: '介護中' },
];
export default LifeStages;
