import firebase from '@firebase/app';
import '@firebase/auth';

let instance = null;

class FirebaseService {
  constructor() {
    if (!instance) {
      this.app = firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API,
        projectId: process.env.REACT_APP_FIREBASE_PID,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      });

      instance = this;
    }

    return instance;
  }
}

const firebaseService = new FirebaseService().app;

export default firebaseService;
