const JobTitles = [
  { label: '社員 / メンバー', value: '社員 / メンバー' },
  { label: '課長クラス / チームリーダー', value: '課長クラス / チームリーダー' },
  { label: '部長クラス / マネージャー', value: '部長クラス / マネージャー' },
  { label: '役員', value: '役員' },
  { label: '経営者', value: '経営者' },
  { label: 'フリーランス', value: 'フリーランス' },
  { label: 'その他 ', value: 'その他 ' },
];
export default JobTitles;
