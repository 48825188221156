// import React from 'react';
import styled from 'styled-components';

export const FormLabel = styled.label`
  color: #111111;
  font-size: 14px;
  font-weight: 600;
`;

export const FormLabelNmr = styled.label`
  color: #111111;
  font-size: 15px;
`;

export const FormLabelSm = styled.label`
  color: #666666;
  font-size: 12px;
`;

export const RequiredLabel = styled.span`
  color: #ff0000;
  font-weight: 400;
  font-size: smaller !important;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: row;
`;
