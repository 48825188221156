import React from 'react';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormLabel, FormRow, FormColumn } from '../../components/Typography';

import uncheckImg from '../../assets/icons/checkCirleBtnOff.png';
import checkImg from '../../assets/icons/checkCirleBtnOn.png';

const CheckIcon = () => <img src={checkImg} alt="" style={{ width: '20px', height: '20px' }} />;
const UnCheckIcon = () => <img src={uncheckImg} alt="" style={{ width: '20px', height: '20px' }} />;

export default class KidsInfo extends React.Component {
  state = {};

  _renderKidYearSelect = (gender) => {
    let { kidsInfo } = this.props.userProfile;
    if (!kidsInfo) kidsInfo = { boys: [], girls: [] };

    const current_year = moment().year();
    let yearsOption = [];
    for (let i = current_year; i >= current_year - 50; i--) {
      yearsOption.push({ label: `${i}年`, value: i });
    }
    let picker = [];
    for (let j = 0; j < kidsInfo[gender].length + 1; j++) {
      picker.push(
        <TextField
          key={j}
          select
          value={kidsInfo[gender][j] ? kidsInfo[gender][j] : ''}
          style={{ width: '150px', marginRight: '10px' }}
          onChange={(e) => this._setKidInfo(e.target.value, gender, j)}>
          {yearsOption.map((year) => (
            <MenuItem key={year.value} value={year.value}>
              {year.label}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    return picker;
  };

  _setKidInfo = (year, gender, index) => {
    let { kidsInfo } = this.props.userProfile;
    if (!kidsInfo) kidsInfo = { boys: [], girls: [] };

    if (year) {
      if (!kidsInfo[gender][index]) kidsInfo[gender].push(year);
      else kidsInfo[gender][index] = year;
    } else {
      kidsInfo[gender].splice(index, 1);
    }
    this.props.setProfile('kidsInfo', kidsInfo);
  };

  render() {
    const { userProfile } = this.props;
    return (
      <section style={{ width: '100%' }}>
        <FormRow>
          <FormLabel>子どもの有無</FormLabel>
          <FormColumn>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<UnCheckIcon />}
                  checkedIcon={<CheckIcon />}
                  name="hasKid"
                  checked={userProfile.hasKid === true}
                  onClick={() => this.props.setProfile('hasKid', true)}
                />
              }
              label="あり"
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<UnCheckIcon />}
                  checkedIcon={<CheckIcon />}
                  name="hasKid"
                  checked={userProfile.hasKid === false}
                  onClick={() => this.props.setProfile('hasKid', false)}
                />
              }
              label="なし"
            />
          </FormColumn>
          {userProfile.hasKid === true && (
            <FormColumn>
              {/* Boys */}
              <FormRow>
                <FormLabel>男の子（誕生年）</FormLabel>
                {this._renderKidYearSelect('boys')}
              </FormRow>

              {/* Girls */}
              <FormRow>
                <FormLabel>女の子（誕生年）</FormLabel>
                {this._renderKidYearSelect('girls')}
              </FormRow>
            </FormColumn>
          )}
        </FormRow>
      </section>
    );
  }
}
