import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !important;
    font-size: 14px;
  }

  // prevent auto zoom in on mobile
  @media screen and (max-width: 767px) {
    input, select, textarea {
      font-size: 16px !important;
    }
  }

  body {
    // background-color: #f5f5f5;
    display: flex;
    min-height: 100vh;
    max-width: 768px;
    margin: 0px auto;
    -webkit-user-select: none; 
    -webkit-touch-callout: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  }
  #root {
    width: 100%;
    position: relative;
  }
  img {
    width: 100%;
    height: auto;
  }
  img.avatar-96x96{
    width: 96px;
    height: 96px;
    border-radius: 48px;
    object-fit: cover;
    object-position: center center;
  }
  img.avatar-48x48{
    width: 48px;
    height: 48px;
    border-radius: 24px;
    object-fit: cover;
    object-position: center center;
  }
  a:focus, img:focus, div:focus, button:focus {
    outline: none !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #999999;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #D8D8D8;
  }
  .MuiInput-underline:after {
    border-bottom-width: 1px !important;
  }

  .MuiBreadcrumbs-li{
    color: #333333;
  }
  .MuiBreadcrumbs-li a,
  .MuiBreadcrumbs-li p  {
    text-decoration: none;
    font-size: 13px;
  }

  .MuiChip-root {
    background-color: #F2F2F2;
  }
  .MuiChip-root.MuiChip-colorPrimary {
    background-color: #FFFFFF;
  }
  .MuiInputAdornment-positionEnd .MuiTypography-colorTextSecondary {
    color: #111111 !important;
  }
  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
  }
  .hr-sect::before,
  .hr-sect::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }
  // Input Number disable arrows
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyle;
