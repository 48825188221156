import axios from 'axios';
import * as types from './constants';
import firebase from '@firebase/app';
import '@firebase/auth';
// import moment from 'moment';
// import jwtDecode from 'jwt-decode';
import firebaseService from '../utils/firebase-service';

import { apiPutRequest } from '../utils/api';

const sessionLogout = () => ({ type: types.SESSION_LOGOUT });
const sessionLoading = () => ({ type: types.SESSION_LOADING });
const sessionRestoring = () => ({ type: types.SESSION_RESTORING });
// const sessionRestoringLocal = () => ({ type: types.SESSION_LOCAL_RESTORING });
const sessionError = (error) => ({ type: types.SESSION_ERROR, error });
const sessionClearError = () => ({ type: types.SESSION_CLEAR_ERROR });
const sessionSuccess = (user) => ({ type: types.SESSION_SUCCESS, user });
const sessionUpdated = (profile) => ({ type: types.SESSION_UPDATED, profile });

export const restoreSession = () => (dispatch) => {
  dispatch(sessionRestoring());
  firebaseService.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      // 2020 - Jul : temporary disable user cache
      // for check notifications count
      // check sessionLocal
      // const userSessionJSON = localStorage.getItem('session');
      // if (!userSessionJSON) {
      //   dispatch(exchangeJWTToken());
      // } else {
      //   const userSession = JSON.parse(userSessionJSON);
      //   const tokenDecoded = jwtDecode(userSession.jwt);
      //   const currentTime = moment().add(7, 'days').unix();
      //   const tokenExpiredTime = parseInt(tokenDecoded.exp);
      //   if (tokenExpiredTime <= currentTime) {
      //     dispatch(exchangeJWTToken());
      //   } else {
      //     dispatch(sessionRestoringLocal());
      //     dispatch(sessionSuccess(userSession));
      //   }
      // }
      dispatch(exchangeJWTToken());
    } else dispatch(sessionLogout());
  });
};

export const exchangeJWTToken = (source = '') => (dispatch) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then((firebaseToken) =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/authService/exchangeToken?firebaseToken=${firebaseToken}&source=${source}`
      )
    )
    .then((userInfo) => {
      localStorage.setItem('session', JSON.stringify(userInfo.data));
      dispatch(sessionSuccess(userInfo.data));
    })
    .catch((e) => {
      const errorDetail = e.response;
      if (errorDetail.data.message === 'user.deleted') {
        dispatch(sessionError('auth/user-not-found'));
      } else {
        dispatch(sessionLogout());
      }
    });
};

export const fbAuth = () => (dispatch) => {
  dispatch(sessionLoading());

  firebaseService
    .auth()
    .signInWithPopup(new firebase.auth.FacebookAuthProvider())
    .then(() => dispatch(exchangeJWTToken('facebook')))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const twAuth = () => (dispatch) => {
  dispatch(sessionLoading());

  firebaseService
    .auth()
    .signInWithPopup(new firebase.auth.TwitterAuthProvider())
    .then(() => dispatch(exchangeJWTToken('twitter')))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const ggAuth = () => (dispatch) => {
  dispatch(sessionLoading());

  firebaseService
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then(() => dispatch(exchangeJWTToken('google')))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const pwAuth = (email, password) => (dispatch) => {
  dispatch(sessionLoading());

  firebaseService
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => dispatch(exchangeJWTToken()))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const resetPwd = (email) => (dispatch) => {
  dispatch(sessionLoading());

  firebaseService
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => dispatch(sessionError('auth/please-check-your-email')))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const signup = (name, email, password) => (dispatch) => {
  dispatch(sessionLoading());
  if (!name) {
    dispatch(sessionError('auth/name-is-null'));
    return;
  }
  firebaseService
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      res.user.updateProfile({ displayName: name }).then(() => dispatch(exchangeJWTToken()));
    })
    .catch((error) => dispatch(sessionError(error.code)));
};

export const update = (jwt, newUserProfile) => (dispatch) => {
  if (!newUserProfile.name || !newUserProfile.imageUrl) return;

  dispatch(sessionLoading());

  const firebaseVars = { displayName: newUserProfile.name, photoURL: newUserProfile.imageUrl };
  const firebaseUser = firebase.auth().currentUser;
  return firebaseUser
    .updateProfile(firebaseVars)
    .then(() => {
      return apiPutRequest(jwt, 'userService/updateMe', { newUserProfile: newUserProfile }).then((u) => {
        let profileUpdated = { ...u, ...firebaseVars };
        let userSessionJSON = localStorage.getItem('session');
        let userSession = JSON.parse(userSessionJSON);
        userSession.info = profileUpdated;
        localStorage.setItem('session', JSON.stringify(userSession));
        dispatch(sessionUpdated(profileUpdated));
      });
    })
    .catch(() => dispatch(sessionError('auth/api-error')));
};

export const updateEmail = (jwt, currentPassword, newEmail) => (dispatch) => {
  if (!currentPassword || !newEmail) return;

  dispatch(sessionLoading());

  return reAuthenticate(currentPassword)
    .then(() => {
      const firebaseUser = firebase.auth().currentUser;
      return firebaseUser
        .updateEmail(newEmail)
        .then(() => {
          return apiPutRequest(jwt, 'userService/updateMe', {
            newUserProfile: { email: newEmail },
          }).then((u) => {
            let userSessionJSON = localStorage.getItem('session');
            let userSession = JSON.parse(userSessionJSON);
            userSession.info = u;
            localStorage.setItem('session', JSON.stringify(userSession));
            dispatch(sessionUpdated(u));
          });
        })
        .catch((error) => dispatch(sessionError(error.code)));
    })
    .catch(() => dispatch(sessionError('auth/api-error')));
};

export const updatePassword = (jwt, currentPassword, newPassword) => (dispatch) => {
  if (!currentPassword || !newPassword) return;

  dispatch(sessionLoading());

  return reAuthenticate(currentPassword)
    .then(() => {
      const firebaseUser = firebase.auth().currentUser;
      return firebaseUser
        .updatePassword(newPassword)
        .then(() => {
          apiPutRequest(jwt, 'userService/updateMe', {
            newUserProfile: {},
          }).then((u) => dispatch(sessionUpdated(u)));
        })
        .catch((error) => dispatch(sessionError(error.code)));
    })
    .catch(() => dispatch(sessionError('auth/api-error')));
};

export const logout = () => (dispatch) => {
  dispatch(sessionLoading());
  localStorage.removeItem('session');
  return firebaseService
    .auth()
    .signOut()
    .then(() => dispatch(sessionLogout()))
    .catch((error) => dispatch(sessionError(error.code)));
};

export const clearError = () => (dispatch) => dispatch(sessionClearError());

const reAuthenticate = (currentPassword) => {
  const firebaseUser = firebase.auth().currentUser;
  var cred = firebase.auth.EmailAuthProvider.credential(firebaseUser.email, currentPassword);
  return firebaseUser.reauthenticateWithCredential(cred);
};
