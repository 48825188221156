import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorLinear = withStyles(() => ({
  root: {
    marginLeft: '6px',
    marginRight: '6px',
    color: '#ffffff !important',
    height: '45px',
    borderRadius: '22px',
    fontWeight: '600',
    background: 'linear-gradient(8.24deg, #7251ff 0%, #68b6ff 98.3%)',
    '&:hover': {
      opacity: 0.9,
    },
    '&:disabled': {
      opacity: 0.5,
      color: '#ffffff !important',
    },
  },
}))(Button);

const ButtonLinear = ({ width = '150px', title, cb, disabled = false }) => (
  <ColorLinear style={{ width: width }} disabled={disabled} onClick={() => cb()}>
    {title}
  </ColorLinear>
);

export default ButtonLinear;
