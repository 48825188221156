export const SESSION_LOADING = 'clarity/session/loading';
export const SESSION_RESTORING = 'clarity/session/restoring';
export const SESSION_LOCAL_RESTORING = 'clarity/session/local-restoring';
export const SESSION_LOCAL_RETORED = 'clarity/session/local-restored';
export const SESSION_FRESH_RESTORING = 'clarity/session/fresh-restoring';
export const SESSION_FRESH_RESTORED = 'clarity/session/fresh-restored';
export const SESSION_EXCHANGE_TOKEN = 'clarity/session/exchange';
export const SESSION_UPDATED = 'clarity/session/updated';
export const SESSION_SUCCESS = 'clarity/session/success';
export const SIGNUP_SUCCESS = 'clarity/session/signup-success';
export const SESSION_ERROR = 'clarity/session/error';
export const SESSION_CLEAR_ERROR = 'clarity/session/clear-error';
export const SESSION_LOGOUT = 'clarity/session/logout';
