import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorOrange = withStyles(() => ({
  root: {
    marginLeft: '6px',
    marginRight: '6px',
    color: '#fff !important',
    height: '45px',
    borderRadius: '22px',
    fontWeight: '600',
    background: '#FF8E56',
    '&:hover': {
      opacity: 0.9,
      background: '#FF8E56',
    },
    '&:disabled': {
      opacity: 0.5,
      color: '#fff !important',
    },
  },
}))(Button);

const ButtonOrange = ({ width = '150px', title, cb, disabled = false }) => (
  <ColorOrange style={{ width: width }} disabled={disabled} onClick={() => cb()}>
    {title}
  </ColorOrange>
);

export default ButtonOrange;
