import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import AppLayout from '../../components/AppLayout';
import { apiPostRequest } from '../../utils/api';

import reviewDoneImg from '../../assets/images/review_done.png';
import ButtonLinear from '../../components/ButtonLinear';

import { FormLabelSm, FormLabel, FormRow } from '../../components/Typography';

class MenteeThankyouContainer extends React.Component {
  state = {
    dialog_open: false,
  };

  validateRid = (rid) => {
    // https://github.com/mongodb/js-bson/blob/c307ca8e81b73ccfcac0b54e5012123773d30ddb/lib/objectid.js#L12
    const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    return checkForHexRegExp.test(rid);
  };

  getFromUrl = () => {
    let { params } = this.props.match;
    if (!params) return 0;
    let { rid, uname } = params;
    if (!rid) rid = 0;
    if (!uname) uname = '';

    return { rid, uname };
  };

  openRequestDialog = () => this.setState({ dialog_open: true });
  closeRequestDialog = () => this.setState({ dialog_open: false });

  async componentDidMount() {
    const { rid, uname } = this.getFromUrl();
    const rid_valid = this.validateRid(rid);
    if (!rid_valid) {
      this.setState({ isGetting: true });
      return;
    }
    this.setState({ rid: rid, uname: uname });
  }

  returnToMsg = () => {
    this.props.history.push(`/r/${this.state.rid}/${this.state.uname}`);
  };

  submit = async () => {
    if (!this.state.message) return;

    this.setState({ submitting: true }, async () => {
      try {
        await apiPostRequest(this.props.session.user.jwt, 'userService/contactClarity', {
          env: process.env.NODE_ENV,
          replyTo: this.props.session.user.info.email,
          message: this.state.message,
          category: 'メンタリング終了',
        });
        this.setState({
          dialog_open: false,
          submitting: false,
          message: null,
          sent: true,
        });
      } catch {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <AppLayout bgWhite>
        {this.state.isGetting ? (
          <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            <Breadcrumbs separator=">" style={{ margin: '24px 0' }}>
              <Link to="/">
                <Typography>Top</Typography>
              </Link>
              <Link to="/message">
                <Typography>メッセージ</Typography>
              </Link>
              <Link to={`/r/${this.state.rid}/${this.state.uname}`}>
                <Typography>{this.state.uname}</Typography>
              </Link>
              <Typography>送信完了</Typography>
            </Breadcrumbs>

            <img src={reviewDoneImg} alt="img-done" />

            <MsgWrapper>
              <strong>ご協力ありがとうございました</strong>
              <br />
              <p>【サービス改善のための運営からのお願い】</p>
              <p>
                メンタリングに関して、アプリの使用感、サービス全体に対するご意見やご要望がございましたら大歓迎です！ぜひ
                <TextLink onClick={() => this.openRequestDialog()}>こちら</TextLink>からお聞かせください。
              </p>
              <ButtonLinear title="閉じる" cb={() => this.returnToMsg()} disabled={false} />
              {this.state.sent && <p style={{ color: 'red' }}>メッセージが送信されました</p>}
            </MsgWrapper>

            <Dialog open={this.state.dialog_open} onClose={this.closeRequestDialog}>
              <DialogContent>
                <FormRow style={{ display: 'flex', alignItems: 'center' }}>
                  <FormLabel>ご意見・ご要望をお聞かせください！</FormLabel>
                  <TextField
                    multiline
                    fullWidth
                    rows={5}
                    value={this.state.message || ''}
                    onChange={(e) => this.setState({ message: e.target.value })}
                  />
                  <br />
                  <FormLabelSm>※こちらの内容は今回のメンター／メンティー相手には送信されません。</FormLabelSm>
                  <br />
                  <ButtonLinear title="送信して閉じる" cb={() => this.submit()} />
                  <br />
                  <TextLink onClick={() => this.closeRequestDialog()}>キャンセル</TextLink>
                </FormRow>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </AppLayout>
    );
  }
}

const MsgWrapper = styled.div`
  margin-top: 15px;
  text-align: center;
`;

const TextLink = styled.span`
  color: #5870ff;
  text-decoration: underline;
  cursor: pointer;
`;

const mapStateToProps = ({ session }) => ({
  session: session,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenteeThankyouContainer);
