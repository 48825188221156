import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ja';
import MomentUtils from '@date-io/moment';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { update } from '../../sessions/actions';
import AppLayout from '../../components/AppLayout';
import { FormLabel, RequiredLabel, FormRow } from '../../components/Typography';

import careerIcon from '../../assets/icons/career.png';
import lifeIcon from '../../assets/icons/life.png';
import penGreenIcon from '../../assets/icons/pen_gr.png';
import penOrangeIcon from '../../assets/icons/pen_or.png';

moment.locale('ja');

const initialState = {
  selectedCategory: null,
  selectedYear: null,
  selectedMonth: null,
  selectedTitle: '',
  selectedSubtitle: '',
  editingEvent: false,
  editingEvent_Category: null,
  editingEvent_CategoryOld: null,
  editingEvent_Year: null,
  editingEvent_YearOld: null,
  editingEvent_Month: null,
  editingEvent_MonthOld: null,
  editingEvent_title: '',
  editingEvent_subtitle: '',
  editingEvent_id: '',
  openEditOverlay: false,
};

class EditTimelineContainer extends React.Component {
  state = initialState;

  handleAddEvent = () => {
    const { selectedCategory, selectedYear, selectedMonth, selectedTitle, selectedSubtitle } = this.state;
    if (!selectedCategory || !selectedYear || !selectedMonth || !selectedTitle) return false;

    this.addEvent(selectedCategory, selectedYear, selectedMonth, selectedTitle, selectedSubtitle);
  };

  addEvent = (selectedCategory, selectedYear, selectedMonth, title, subtitle) => {
    const { userProfile } = this.state;
    let { timeline } = userProfile;
    if (!timeline) timeline = {};
    if (!timeline[selectedYear]) timeline[selectedYear] = {};
    if (!timeline[selectedYear][selectedMonth]) timeline[selectedYear][selectedMonth] = { career: [], life: [] };
    timeline[selectedYear][selectedMonth][selectedCategory].push({
      title: title,
      subtitle: subtitle,
      id: new Date().getTime(),
    });
    // sorting
    let timelineOrdered = {};
    Object.keys(timeline)
      .sort()
      .forEach((k) => (timelineOrdered[k] = timeline[k]));
    for (let year of Object.keys(timelineOrdered)) {
      let tmpMonthsOrdered = {};
      Object.keys(timelineOrdered[year])
        .sort()
        .forEach((k) => (tmpMonthsOrdered[k] = timelineOrdered[year][k]));
      timelineOrdered[year] = tmpMonthsOrdered;
    }
    this.setState(
      { userProfile: { ...this.state.userProfile, timeline: timelineOrdered }, ...initialState },
      async () => {
        await this.handleSaveProfile();
      }
    );
  };

  handleSaveProfile = async () => {
    await this.props.updateProfile(this.props.session.user.jwt, this.state.userProfile);
  };

  handleRemoveEvent = () => {
    // eslint-disable-next-line no-restricted-globals
    const conf = confirm(`“${this.state.editingEvent_title}”を削除しますか？`);
    if (conf === true) {
      this.setState({ openEditOverlay: false }, () => {
        this.removeEvent(
          this.state.editingEvent_YearOld,
          this.state.editingEvent_MonthOld,
          this.state.editingEvent_CategoryOld,
          this.state.editingEvent_id
        );
      });
    }
  };

  removeEvent = (year, month, type, id) => {
    const { userProfile } = this.state;
    let { timeline } = userProfile;

    timeline[year][month][type] = timeline[year][month][type].filter((x) => x.id !== id);

    if (!timeline[year][month].career.length && !timeline[year][month].life.length) {
      delete timeline[year][month];
    }
    if (!Object.keys(timeline[year]).length) delete timeline[year];
    this.setState({ userProfile: { ...this.state.userProfile, timeline: timeline } }, async () => {
      await this.handleSaveProfile();
    });
  };

  handleEditEvent = (category, year, month, title, subtitle, id) => {
    this.setState({
      openEditOverlay: true,
      editingEvent: true,
      editingEvent_Category: category,
      editingEvent_CategoryOld: category,
      editingEvent_Year: parseInt(year),
      editingEvent_YearOld: parseInt(year),
      editingEvent_Month: parseInt(month),
      editingEvent_MonthOld: parseInt(month),
      editingEvent_title: title,
      editingEvent_subtitle: subtitle,
      editingEvent_id: id,
    });
  };

  saveEditingEvent = () => {
    const {
      editingEvent_CategoryOld,
      editingEvent_Category,
      editingEvent_Month,
      editingEvent_MonthOld,
      editingEvent_Year,
      editingEvent_YearOld,
      editingEvent_title,
      editingEvent_subtitle,
      editingEvent_id,
    } = this.state;

    if (!editingEvent_Category) return false;
    if (!editingEvent_Year) return false;
    if (!editingEvent_Month) return false;

    this.removeEvent(editingEvent_YearOld, editingEvent_MonthOld, editingEvent_CategoryOld, editingEvent_id);

    this.addEvent(
      editingEvent_Category,
      editingEvent_Year,
      editingEvent_Month,
      editingEvent_title,
      editingEvent_subtitle
    );
  };

  componentDidMount() {
    let tmpProfile = this.props.session.user.info;
    this.setState({ userProfile: tmpProfile });
  }
  render() {
    const { userProfile } = this.state;
    if (!userProfile) return <div />;

    return (
      <AppLayout bgWhite>
        {/*  */}
        <SectionRow>
          <SectionLabel>年表に追加</SectionLabel>
        </SectionRow>

        {/*  */}
        <FormRow>
          <FormLabel>
            カテゴリ<RequiredLabel>*</RequiredLabel>
          </FormLabel>
          <TextField
            select
            value={this.state.selectedCategory || ''}
            onChange={(e) => this.setState({ selectedCategory: e.target.value })}>
            <MenuItem value={`career`}>キャリア</MenuItem>
            <MenuItem value={`life`}>ライフ</MenuItem>
          </TextField>
        </FormRow>
        <FormRow>
          <FormLabel>
            期間<RequiredLabel>*</RequiredLabel>
          </FormLabel>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locacle={'ja'}>
            <DatePicker
              disableFuture
              openTo="year"
              format="YYYY-MMM"
              views={['year', 'month']}
              value={
                this.state.selectedYear && this.state.selectedMonth
                  ? new Date(this.state.selectedYear, this.state.selectedMonth - 1)
                  : null
              }
              onChange={(date) => this.setState({ selectedYear: date.format('YYYY'), selectedMonth: date.format('M') })}
            />
          </MuiPickersUtilsProvider>
        </FormRow>
        <FormRow>
          <FormLabel>
            出来事<RequiredLabel>*</RequiredLabel>
          </FormLabel>
          <TextField
            placeholder="例）大学卒業"
            value={this.state.selectedTitle || ''}
            onChange={(e) => this.setState({ selectedTitle: e.target.value })}
          />
        </FormRow>
        <FormRow>
          <FormLabel>詳細</FormLabel>
          <TextField
            placeholder="例）◯◯大学××××学部を卒業"
            value={this.state.selectedSubtitle || ''}
            onChange={(e) => this.setState({ selectedSubtitle: e.target.value })}
          />
        </FormRow>
        <FormRow style={{ margin: '24px 0' }}>
          <Button variant="outlined" onClick={() => this.handleAddEvent()} startIcon={<SaveIcon />}>
            追加して保存
          </Button>
        </FormRow>

        {/*  */}
        <br />
        <SectionRow>
          <SectionLabel>プレビュー</SectionLabel>
        </SectionRow>

        <SectionRow>
          <WorkLikeHeaderWraper>
            <img style={{ width: '20px', height: '18px' }} src={careerIcon} alt="" />
            <span style={{ color: '#00C8B1' }}>キャリア</span>
          </WorkLikeHeaderWraper>

          <WorkLikeHeaderWraper>
            <img style={{ width: '18px', height: '20px' }} src={lifeIcon} alt="" />
            <span style={{ color: '#EF9C4B' }}>ライフ</span>
          </WorkLikeHeaderWraper>
        </SectionRow>

        {userProfile.timeline && (
          <React.Fragment>
            {Object.keys(userProfile.timeline).map((year) => (
              <React.Fragment key={year}>
                <SectionRow style={{ justifyContent: 'center' }}>
                  <WorkLifeEventYear>{year}年</WorkLifeEventYear>
                </SectionRow>
                <SectionRow>
                  <BoxWorkEvents>
                    {Object.keys(userProfile.timeline[year]).map((month) => (
                      <React.Fragment key={month}>
                        {userProfile.timeline[year][month].career.map((e, index) => (
                          <EventSingle
                            key={index}
                            onClick={() => this.handleEditEvent('career', year, month, e.title, e.subtitle, e.id)}>
                            <div style={{ paddingTop: '12px' }}>{month}月</div>
                            <div style={{ fontSize: 15, color: '#3BCCAD' }}>
                              <img
                                src={penGreenIcon}
                                alt=""
                                style={{ width: '14px', height: '14px', marginRight: '5px' }}
                              />
                              {e.title}
                            </div>
                            <div>{e.subtitle}</div>
                            <WorkEventDot />
                          </EventSingle>
                        ))}
                      </React.Fragment>
                    ))}
                  </BoxWorkEvents>

                  <BoxLifeEvents>
                    {Object.keys(userProfile.timeline[year]).map((month) => (
                      <React.Fragment key={month}>
                        {userProfile.timeline[year][month].life.map((e, index) => (
                          <EventSingle
                            key={index}
                            onClick={() => this.handleEditEvent('life', year, month, e.title, e.subtitle, e.id)}>
                            <div style={{ paddingTop: '12px' }}>{month}月</div>
                            <div style={{ fontSize: 15, color: '#EF9C4B' }}>
                              {e.title}
                              <img
                                src={penOrangeIcon}
                                alt=""
                                style={{ width: '14px', height: '14px', marginLeft: '5px' }}
                              />
                            </div>
                            <div>{e.subtitle}</div>
                            <LifeEventDot />
                          </EventSingle>
                        ))}
                      </React.Fragment>
                    ))}
                  </BoxLifeEvents>
                </SectionRow>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        <Dialog open={this.state.openEditOverlay} onClose={() => this.setState({ openEditOverlay: false })}>
          <DialogTitle>エディット</DialogTitle>
          <DialogContent>
            <FormRow>
              <FormLabel>
                カテゴリ<RequiredLabel>*</RequiredLabel>
              </FormLabel>
              <TextField
                select
                value={this.state.editingEvent_Category || ''}
                onChange={(e) => this.setState({ editingEvent_Category: e.target.value })}>
                <MenuItem value={`career`}>キャリア</MenuItem>
                <MenuItem value={`life`}>ライフ</MenuItem>
              </TextField>
            </FormRow>
            <FormRow>
              <FormLabel>
                期間<RequiredLabel>*</RequiredLabel>
              </FormLabel>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locacle={'ja'}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format="YYYY-MMM"
                  views={['year', 'month']}
                  value={
                    this.state.editingEvent_Year && this.state.editingEvent_Month
                      ? new Date(this.state.editingEvent_Year, this.state.editingEvent_Month - 1)
                      : null
                  }
                  onChange={(date) =>
                    this.setState({ editingEvent_Year: date.format('YYYY'), editingEvent_Month: date.format('M') })
                  }
                />
              </MuiPickersUtilsProvider>
            </FormRow>
            <FormRow>
              <FormLabel>
                出来事<RequiredLabel>*</RequiredLabel>
              </FormLabel>
              <TextField
                placeholder="例）大学卒業"
                value={this.state.editingEvent_title || ''}
                onChange={(e) => this.setState({ editingEvent_title: e.target.value })}
              />
            </FormRow>
            <FormRow>
              <FormLabel>詳細</FormLabel>
              <TextField
                placeholder="例）◯◯大学××××学部を卒業"
                value={this.state.editingEvent_subtitle || ''}
                onChange={(e) => this.setState({ editingEvent_subtitle: e.target.value })}
              />
            </FormRow>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleRemoveEvent()} color="secondary">
              x この項目を削除
            </Button>
            <Button onClick={() => this.setState({ openEditOverlay: false })} color="primary">
              キャンセル
            </Button>
            <Button onClick={() => this.saveEditingEvent()} color="primary">
              保存
            </Button>
          </DialogActions>
        </Dialog>
        <br />
        <br />
      </AppLayout>
    );
  }
}

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`;

const SectionLabel = styled.p`
  color: #666666;
  font-weight: 600;
`;

const WorkLikeHeaderWraper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > span {
    font-size: 15px;
    font-weight: 600;
    margin-left: 5px;
  }
`;

const WorkLifeEventYear = styled.div`
  background-color: #f5f5f5;
  width: 75px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
`;

const BoxWorkEvents = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eeeeee;
  text-align: right;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 24px;
`;

const BoxLifeEvents = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #eeeeee;
  text-align: left;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 24px;
`;

const EventSingle = styled.div`
  position: relative;
  cursor: pointer;
`;

const WorkEventDot = styled.div`
  margin-right: -16px;
  width: 6px;
  height: 6px;
  background-color: #00c8b1;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
`;

const LifeEventDot = styled.div`
  margin-left: -16px;
  width: 6px;
  height: 6px;
  background-color: #ef9c4b;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15px;
`;

const mapStateToProps = ({ session }) => ({
  session: session,
});

const mapDispatchToProps = {
  updateProfile: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTimelineContainer);
